var Flickity = require("flickity");
var asNavFor = require("flickity-as-nav-for");
var flickityFade = require("flickity-fade");

const tabbed_content_tab_titles = document.querySelectorAll('.tabbed-content__tab-titles');
const tabbed_content_wrappers = document.querySelectorAll('.tabbed-content__content-wrapper');

let tabbed_content_tab_flickities = [];
let tabbed_content_wrapper_flickities = [];

for( let i=0; i<tabbed_content_tab_titles.length; i++ ){

    try {
        tabbed_content_tab_flickities.push( new Flickity( tabbed_content_tab_titles[i], {
            pageDots: false,
            prevNextButtons: false,
            asNavFor: ".tabbed-content__content-wrapper",
            cellAlign: 'left',
            contain: true,
        }) );

    } catch (error) {
        console.log('Error setting up Flickity for tab titles:');
        console.log(error);
    }


}

for( let i=0; i<tabbed_content_wrappers.length; i++ ){
    try {
        tabbed_content_wrapper_flickities.push( new Flickity(tabbed_content_wrappers[i], {
            fade: true,
            adaptiveHeight: true,
            pageDots: false,
            prevNextButtons: false,
        }) );

    } catch (error) {
        console.log('Error setting up Flickity for wrappers:');
        console.log(error);
    }

}


// let tab_taglines = document.querySelectorAll('.tabbed-content__tagline');
let tab_titles = document.querySelectorAll('.tabbed-content__tab-title');
for( let i=0; i<tab_titles.length; i++ ){
    tab_titles[i].addEventListener( 'click', tab_title_onClick, {
        capture: true,
        passive: true
      } );
}
function tab_title_onClick(e){

    try {
        let this_tab = e.currentTarget;
        // console.log('clicked tab' );
        // console.log(e);
        // console.log(this_tab);

        let parent_container = this_tab;
        do {
            parent_container = parent_container.parentNode;
        }
        while( !parent_container.classList.contains('tabbed-content') && parent_container !== document.body );

        // Trigger animation on the logo mark.
		// Method for removing/adding a class to retrigger a CSS animation: https://css-tricks.com/restart-css-animation/
        let animated_background = parent_container.querySelector('.tabbed-content__animated-background');
        animated_background.classList.remove('is-animated');
        void animated_background.offsetWidth;
		animated_background.classList.add('is-animated');

        // Set active tagline
        let active_tagline = parent_container.querySelector('.tabbed-content__tagline.is-active');
        if( active_tagline ){
            active_tagline.classList.remove('is-active');
        }

        let new_active_tab_selector = '.tabbed-content__tagline[data-num="' + this_tab.dataset.num + '"]';
        let new_active_tagline = parent_container.querySelector(new_active_tab_selector);
        if( new_active_tagline ){
            new_active_tagline.classList.add('is-active');
        }

        // Set active path in logo mark.
		let active_path = parent_container.querySelector('path.is-active');
		if( active_path ){
            active_path.classList.remove('is-active');
		}

		let new_active_path_selector = '.logo-mark__curve-' + this_tab.dataset.num;
		let new_active_path = parent_container.querySelector(new_active_path_selector);
		if( new_active_path ){
            new_active_path.classList.add('is-active');
		}


    } catch (error) {
        console.log('Error in tab click:');
        console.log(error);
    }

}

try {
	const tabbed_content = document.querySelectorAll('.tabbed-content');
	for( let i=0; i<tabbed_content.length; i++ ){
		let active_path_on_load = tabbed_content[i].querySelector('.logo-mark__curve-1');
		if( active_path_on_load ){
			active_path_on_load.classList.add('is-active')
		}
	}

} catch (error) {
	console.log('Error setting the starting active path in tabbed content layouts:');
	console.log(error);
}
