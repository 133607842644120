////////////////////////////////////////////
// Header height ///////////////////////////
// There is a CSS custom property `--header-height` that controls the size/position of the mega nav. The height must be updated when the window is resized or scrolled, since the height of the header can change after either of those events.

document.addEventListener('DOMContentLoaded', function() {
  prelude_update_header_height_property();
}, false);

window.addEventListener('resize', prelude_update_header_height_property), {passive: true};
window.addEventListener('scroll', prelude_update_header_height_property, {passive: true});

function prelude_update_header_height_property(){
  let header_height = document.querySelector('.site-header').clientHeight;
  if( header_height ){
    document.documentElement.style.setProperty( '--header-height', header_height + 'px' );
  }
}





//////////////////////////////////////////////
// Language menu toggle //////////////////////

// Toggle the language menu open and close.
const language_toggle = document.querySelector('.js-language-toggle');
if( language_toggle ){
  language_toggle.addEventListener('click', onClick_language_toggle, {
    capture: true,
    passive: true
  });
}

function onClick_language_toggle(e){

  try {

    let this_button = e.currentTarget;
    // console.log(this_button);

    if( this_button.parentNode.classList.contains('is-open') ){
      this_button.parentNode.classList.remove('is-open');

    } else {
      this_button.parentNode.classList.add('is-open');
    }

  }
  catch(error){
    console.log('Error toggling language menu:');
    console.log(error);
  }

}

// Close the language menu when a language link is clicked.
const language_links = document.querySelectorAll('.lang-item a');
if( language_links.length ){
  for( let i=0; i < language_links.length; i++ ){
    language_links[i].addEventListener( 'click', onClick_language_link );
  }
}

function onClick_language_link(e){

  try {

    let this_link = e.currentTarget;

    let parent_container = this_link;
    do {
      parent_container = parent_container.parentNode;
    }
    while( !parent_container.classList.contains('.is-open') && parent_container !== document.body );

    if( parent_container.classList.contains('is-open') ){
      parent_container.classList.remove('is-open');
    };

  }
  catch(error){
    console.log('Error closing language menu:');
    console.log(error);
  }

}




////////////////////////////////////////////
// Sticky header ///////////////////////////

document.addEventListener('DOMContentLoaded', function() {
  prelude_stickyHeader();
}, false);

window.addEventListener( 'resize', prelude_stickyHeader, {passive: true} );
window.addEventListener( 'scroll', prelude_stickyHeader, {passive: true} );
// var prelude_stickyHeader_timeout;

function prelude_stickyHeader() {
	try {
		// clearTimeout(prelude_stickyHeader_timeout);
		// prelude_stickyHeader_timeout = setTimeout( function(){
			let scroll_position = window.pageYOffset;
			let header = document.querySelector('.site-header');
			let header_height = header.clientHeight;

			if( (scroll_position > header_height && window.jp_scrollDirection === 'up') || scroll_position <= header_height ){
				header.classList.add('is-sticky');
			} else {
				header.classList.remove('is-sticky');
			}

			if( scroll_position <= header_height ){
				header.classList.add('is-at-top');
			} else {
				header.classList.remove('is-at-top');
			}

		// }, 50);


	}
	catch(error){
		console.log(error);
	}

}





////////////////////////////////////////////////
// Toggle mobile nav ///////////////////////////

// Toggle the mobile nav open and close when the hamburger button is clicked.
const mobile_nav_toggle = document.querySelector('.js-mobile-nav-toggle');
if( mobile_nav_toggle ){
  mobile_nav_toggle.addEventListener('click', onClick_mobile_nav_toggle, {
    capture: true,
    passive: true
  });
}

function onClick_mobile_nav_toggle(e){
  e.preventDefault();

  try {

    if( document.documentElement.classList.contains('mobile-nav--is-open') ){
			document.documentElement.classList.remove('mobile-nav--is-open');

		} else {
			document.documentElement.classList.add('mobile-nav--is-open');
		}

  }
	catch(error){
		console.log(error);
	}

}
