/**
 * Hero - Background Animation
 *
 */

const animated_hero = document.querySelector('.hero--background-animation');
// console.log('animated_hero = ');
// console.log(animated_hero);

const hero_intro_video = document.querySelector('.hero__animation-video--intro');
// console.log('hero_intro_video = ');
// console.log(hero_intro_video);
const hero_loop_video = document.querySelector('.hero__animation-video--loop');
// console.log('hero_loop_video = ');
// console.log(hero_loop_video);
const hero_videos = [
    hero_intro_video,
    hero_loop_video
];

let hero_videos_loaded = [];

function prelude_hero_check_if_videos_loaded(){
    // console.log('%%%%% START prelude_hero_check_if_videos_loaded() %%%%%');

    for( let i=0; i < hero_videos.length; i++ ){
        if( hero_videos[i].readyState === 4 ){
            hero_videos_loaded[i] = hero_videos[i];
        }
    }

    if( hero_videos_loaded.length < hero_videos.length ){
        setTimeout(prelude_hero_check_if_videos_loaded, 200);

    } else {
        // console.log('videos loaded, start slider now');
        animated_hero.classList.add('is-loaded');
        prelude_hero_init_animations();
    }

}

if( animated_hero ){
    prelude_hero_check_if_videos_loaded();
}



function prelude_hero_init_animations(){
    // console.log('%%%%% START prelude_hero_init_animations() %%%%%');
    hero_intro_video.setAttribute('playsinline', '1');
    hero_intro_video.play();
    hero_loop_video.pause();

    let intro_duration = 3063;//3100; //2970 //hero_intro_video.duration * 1000;
    // console.log('intro_duration = ' + intro_duration );

    hero_intro_video.addEventListener('timeupdate', (e) => {
        // console.log('timeupdate');
        // console.log(e);
        // console.log(e.timeStamp);
        // console.log(hero_intro_video.currentTime);

        // if( e.timeStamp > intro_duration ){
        //     // alert('intro ended');
        //     prelude_hero_intro_video_ended();
        // }
        if( (hero_intro_video.currentTime * 1000) > intro_duration ){ // 3078
            prelude_hero_end_intro();
        }
    });


    hero_intro_video.addEventListener('ended', prelude_hero_intro_video_ended);
}

function prelude_hero_end_intro(){
    hero_intro_video.classList.add('is-done');

    hero_loop_video.removeAttribute('style');
    hero_loop_video.setAttribute('playsinline', '1');
    hero_loop_video.setAttribute('loop', '1');
    hero_loop_video.play();
}

function prelude_hero_intro_video_ended(){
    // console.log('%%%%% START prelude_hero_intro_video_ended() %%%%%');
    // console.log('INTRO VIDEO DONE');
    // hero_intro_video.setAttribute('playsinline', '0');
    hero_intro_video.removeAttribute('playsinline');
    hero_intro_video.pause();
    // hero_intro_video.classList.add('is-done');

    // hero_loop_video.removeAttribute('style');
    // hero_loop_video.setAttribute('playsinline', '1');
    // hero_loop_video.setAttribute('loop', '1');
    // hero_loop_video.play();
}




/**
 * Hero - Contact Page
 *
 */

const hero_location_buttons = document.querySelectorAll('.hero__location-button');
const hero_location_visuals = document.querySelectorAll('.hero__location-visual ');

try {

    if( hero_location_buttons.length && hero_location_visuals.length ){

        for( let i=0; i<hero_location_buttons.length; i++ ){

            hero_location_buttons[i].addEventListener('mouseover', prelude_hero_location_buttons_callback, {
                capture: true,
                passive: true
            } );

            hero_location_buttons[i].addEventListener('click', prelude_hero_location_buttons_callback, {
                capture: true,
                passive: true
            } );

        }

        if( window.innerWidth >= 768 ){
            document.querySelector('.hero__location-visual.is-active video').play()
        };

    }

} catch (error) {
    console.log('Error with location button mouseover listeners:', error);
}

function prelude_hero_location_buttons_callback(e){
    if( window.innerWidth >= 768 ){
        const this_location_button = e.currentTarget;
        prelude_hero_location_buttons_set_active(this_location_button);
    }
}

function prelude_hero_location_buttons_set_active(this_location_button){
    if( window.innerWidth >= 768 ){

        // const this_location_button = e.currentTarget;
        const this_location_name = this_location_button.dataset.location;
        const visual_selector = '.hero__location-visual[data-location=' + this_location_name + ']';

        if( !this_location_button.classList.contains('is-active') ){

            for( let i=0; i<hero_location_buttons.length; i++ ){
                hero_location_buttons[i].classList.remove('is-active');
            }

            this_location_button.classList.add('is-active');

        }

        if( document.querySelector(visual_selector).querySelector('video').paused ){

            for( let i=0; i<hero_location_visuals.length; i++ ){
                hero_location_visuals[i].classList.remove('is-active');
                hero_location_visuals[i].querySelector('video').pause();
            }

            // console.log('visual_selector = ', visual_selector);
            document.querySelector(visual_selector).classList.add('is-active');
            document.querySelector(visual_selector).querySelector('video').play();

        }

    }
}

const hero_location_visual_items = document.querySelectorAll('.hero__visual-container--contact .hero__visual-image-container--has-video');
// console.log('hero_location_visual_items = ', hero_location_visual_items);

function prelude_hero_location_mobile_play(){
    // console.log('Doing prelude_hero_location_mobile_play()...');
    try {
        if( window.innerWidth < 768 ){

            for( let i=0; i<hero_location_visual_items.length; i++ ){
                if( hero_location_visual_items[i].classList.contains('is-inView') ){
                    hero_location_visual_items[i].querySelector('video').play();

                } else {
                    hero_location_visual_items[i].querySelector('video').pause();
                }
            }

        }
    } catch (error) {
        console.log('Error playing the location videos on scroll:', error);
    }
}

function prelude_hero_location_pause_videos_on_resize(){
    try {
        if( window.innerWidth >= 768 ){

            for( let i=0; i<hero_location_visual_items.length; i++ ){
                hero_location_visual_items[i].querySelector('video').pause();
            }

            let active_location_buttons = document.querySelectorAll('.hero__location-button.is-active');
            console.log('active_location_buttons = ', active_location_buttons);

            for( let i=0; i<active_location_buttons.length; i++ ){
                prelude_hero_location_buttons_set_active(active_location_buttons[i]);
            }

        }
    } catch (error) {
        console.log('Error pausing the location videos on resize:', error);
    }
}

if( hero_location_visual_items.length ){

    prelude_hero_location_mobile_play();

    window.addEventListener('scroll', prelude_hero_location_mobile_play, {
        'passive' : true
    } );

    window.addEventListener('resize', prelude_hero_location_pause_videos_on_resize, {
        'passive' : true
    } );

}
