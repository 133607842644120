let is_scrolling_start = 0;
let is_scrolling_end = 0;

// try {

// 	body.addEventListener('touchstart', () => {
// 		is_scrolling_start = window.scrollY;
// 	}, {
// 		passive: true
// 	});

// 	body.addEventListener('touchend', () => {
// 		is_scrolling_end = window.scrollY;
// 	}, {
// 		passive: true
// 	});

// } catch(error){
// 	console.log('Error setting is_scrolling_start or is_scrolling_end', error);
// }

function window_is_scrolling(){
	try {

	} catch (error) {
		console.log('Error in window_is_scrolling()', error);
	}
	let window_is_scrolling = false;
	let diff = is_scrolling_end - is_scrolling_start;
	if( diff < 1 ){
		diff = diff * -1;
	}

	if( diff > 5 ){
		window_is_scrolling = true;
	}

	return window_is_scrolling;

}

try {
    let footer_location_videos = document.querySelectorAll('.site-footer__location-image-container--has-video');


	if( footer_location_videos.length ){
		for( let i=0; i<footer_location_videos.length; i++ ){

			// Mouseover an mouseout events for desktops.
			footer_location_videos[i].addEventListener('mouseover', mouseover_callback, {
				capture: true,
				passive: true
			});

			footer_location_videos[i].addEventListener('mouseout', mouseover_callback, {
				capture: true,
				passive: true
			});

            function mouseover_callback(e) {
                if ( document.querySelector('.site-footer__location-image-container--has-video.is-active') ) {
                    document.querySelector('.site-footer__location-image-container--has-video.is-active').classList.remove('is-active');
                }
                if ( e.type == 'mouseover' ) {
                    e.currentTarget.classList.add('is-active');
                    e.currentTarget.querySelector('video').play();
                } else if ( e.type == 'mouseout' ) {
                    e.currentTarget.querySelector('video').pause();
                }
            }

			// Touchstart and touchend events for mobile devices.
			footer_location_videos[i].addEventListener('touchstart', (e) => {
				e.preventDefault();

				is_scrolling_start = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
				// console.log('TOUCHSTART is_scrolling_start = ', is_scrolling_start);

			}, {
				capture: true,
				passive: true
			});

			footer_location_videos[i].addEventListener('touchend', (e) => {
				e.preventDefault();

				is_scrolling_end = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
				// console.log('TOUCHEND is_scrolling_end = ', is_scrolling_end);
				if( !window_is_scrolling() ){

					let this_video = e.currentTarget.querySelector('video');
					if( this_video.paused ){
						this_video.play();
						this_video.parentElement.classList.add('is-hovered');
						this_video.parentElement.classList.add('is-active');
					} else {
						this_video.pause();
						this_video.parentElement.classList.remove('is-hovered');
						this_video.parentElement.classList.remove('is-active');
					}

				}

			}, {
				capture: true,
				passive: true
			});

			footer_location_videos[i].addEventListener('touchcancel', (e) => {
				e.preventDefault();

				is_scrolling_end = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
				// console.log('TOUCHEND is_scrolling_end = ', is_scrolling_end);
				if( !window_is_scrolling() ){

					let this_video = e.currentTarget.querySelector('video');
					if( this_video.paused ){
						this_video.play();
						this_video.parentElement.classList.add('is-hovered');
					} else {
						this_video.pause();
						this_video.parentElement.classList.remove('is-hovered');
					}

				}

			}, {
				capture: true,
				passive: true
			});

		}

        if( window.innerWidth >= 768 ){
            document.querySelector('.site-footer__location-image-container--has-video.is-active video').play();
        } 

        window.addEventListener('scroll', prelude_hero_location_mobile_play, {
            'passive' : true
        } );
	}

    function prelude_hero_location_mobile_play(){
        // console.log('Doing prelude_hero_location_mobile_play()...');
        try {
            if( window.innerWidth < 768 ){
    
                for( let i=0; i<footer_location_videos.length; i++ ){
                    if( footer_location_videos[i].classList.contains('is-inView') ){
                        footer_location_videos[i].querySelector('video').play();
                        console.log(footer_location_videos[i].querySelector('video').play());
                    } else {
                        footer_location_videos[i].querySelector('video').pause();
                    }
                }
    
            }
        } catch (error) {
            console.log('Error playing the location videos on scroll:', error);
        }
    }
    

} catch (error) {
	console.log( 'Error playing the footer videos on hover:', error );
}

