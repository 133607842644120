const content_rotator_buttons = document.querySelectorAll('.content-rotator__button');
for( let i=0; i<content_rotator_buttons.length; i++ ){
	content_rotator_buttons[i].addEventListener(
		'click',
		content_rotator_button_onClick,
		{
			passive: true,
			capture: true
		}
	);

	content_rotator_buttons[i].addEventListener(
		'mouseover',
		content_rotator_button_onClick,
		{
			passive: true,
			capture: true
		}
	);
}

function content_rotator_button_onClick(e){
	try {
		let this_button = e.currentTarget;
		// console.log('clicked button' );
		// console.log(e);
		// console.log(this_button);

		if( !this_button.classList.contains('is-active') ){

			let parent_container = this_button;
			do {
				parent_container = parent_container.parentNode;
			}
			while( !parent_container.classList.contains('content-rotator') && parent_container !== document.body );
			// console.log(parent_container);


			// Trigger animation on the logo mark.
			// Method for removing/adding a class to retrigger a CSS animation: https://css-tricks.com/restart-css-animation/
			let animated_background = parent_container.querySelector('.content-rotator__animated-background');
			animated_background.classList.remove('is-animated');
			void animated_background.offsetWidth;
			animated_background.classList.add('is-animated');
			// console.log('animated_background = ');
			// console.log(animated_background);


			// Set active description.
			let active_description = parent_container.querySelector('.content-rotator__description.is-active');
			if( active_description ){
				active_description.classList.remove('is-active');
			}
			// console.log('active_description = ');
			// console.log(active_description);

			let new_active_tab_selector = '.content-rotator__description[data-num="' + this_button.dataset.num + '"]'
			let new_active_description = parent_container.querySelector(new_active_tab_selector);
			if( new_active_description ){
				new_active_description.classList.add('is-active');
			}
			// console.log('new_active_description = ');
			// console.log(new_active_description);


			// Set active button.
			let active_button = parent_container.querySelector('.content-rotator__button.is-active');
			if( active_button ){
				active_button.classList.remove('is-active');
			}
			this_button.classList.add('is-active');
			// console.log('active_button = ');
			// console.log(active_button);


			// Set active path in logo mark.
			let active_path = parent_container.querySelector('path.is-active');
			if( active_path ){
				active_path.classList.remove('is-active');
			}
			// console.log('active_path = ');
			// console.log(active_path);

			let new_active_path_selector = '.logo-mark__curve-' + this_button.dataset.num;
			let new_active_path = parent_container.querySelector(new_active_path_selector);
			if( new_active_path ){
				new_active_path.classList.add('is-active');
			}
			// console.log('new_active_path = ');
			// console.log(new_active_path);

		}

	} catch (error) {
		console.log('Error in tab click:');
		console.log(error);
	}

}

try {
	const content_rotators = document.querySelectorAll('.content-rotator');
	for( let i=0; i<content_rotators.length; i++ ){
		let active_path_on_load = content_rotators[i].querySelector('.logo-mark__curve-1');
		if( active_path_on_load ){
			active_path_on_load.classList.add('is-active')
		}
	}

} catch (error) {
	console.log('Error setting the starting active path in content rotators:');
	console.log(error);
}
