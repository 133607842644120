const office_location_times = Array.prototype.slice.call( document.querySelectorAll('.js__location-time') );
// let now = Date();

const days_of_week = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday'
];

try {

	for( let i=0; i<office_location_times.length; i++ ){
		if( office_location_times[i].dataset.timezone ){
			let timezone = office_location_times[i].dataset.timezone;
			let this_local_datetime_string = new Date().toLocaleString('en-US', {timeZone: timezone });
			let this_local_date = new Date( this_local_datetime_string );

			// Format a string from the time object, like the PHP date format 'l g:i a'
			let dayName = days_of_week[ this_local_date.getDay() ];
			let hours = this_local_date.getHours();
			let am_pm = 'am';
			if( hours > 12 ){
				hours = hours - 12;
				am_pm = 'pm';
			}
			let minutes = this_local_date.getMinutes();
			if( minutes < 10 ){
				minutes = '0' + minutes;
			}
			let new_time_string = dayName + ' ' + hours + ':' + minutes + ' ' + am_pm;

			office_location_times[i].innerHTML = new_time_string;
			// console.log({
			// 	'timezone': timezone,
			// 	'new-time': this_local_date,
			// 	'new-innerhtml': new_time_string
			// });
		}
	}

} catch (error) {
	console.log( 'Error setting office location times: ', error );
}
