

// Populate the content_container_boundaries[] array (which must already exist on a global scope) with the scroll positions of the content rows. This is run here on page load, and should also be ran inside the callback function in web-font-loader.js, so it will run after the fonts are loaded.
let content_container_boundaries = [];

let content_containers = document.querySelectorAll('.content-with-visual__content');
let visual_containers = document.querySelectorAll('.content-with-visual__visual');

// prelude_set_content_container_boundaries();
window.addEventListener( 'load', prelude_set_content_container_boundaries );

// Redetermine the boundaries whenever the browser is resized.
window.addEventListener( 'resize', prelude_set_content_container_boundaries );

function prelude_set_content_container_boundaries(){

	try {
		// Reset to a blank array so we don't keep appending the same items.
		content_container_boundaries = [];

		for( let i=0; i<content_containers.length; i++ ){
			let this_content_container_shape = content_containers[i].getBoundingClientRect();
			// console.log(this_content_container_shape);

			// For each content container, save where it's top and bottom edges are as scroll distances. As if you viewed the entire page at once and could point out the horizontal lines where each section begins and ends.
			let new_content_container_section_boundary = [];
			new_content_container_section_boundary['top'] = this_content_container_shape.top + window.pageYOffset;
			new_content_container_section_boundary['bottom'] = this_content_container_shape.top + this_content_container_shape.height + window.pageYOffset;
			new_content_container_section_boundary['height'] = new_content_container_section_boundary['bottom'] - new_content_container_section_boundary['top'];
			new_content_container_section_boundary['element'] = content_containers[i];


			// Add the content container data to the global array, which will be referenced in the scroll listener.
			content_container_boundaries.push( new_content_container_section_boundary );

		}
		// console.log(content_container_boundaries);

	}
	catch(e){
		console.log('Error in `prelude_set_content_container_boundaries()`');
		console.log(e);
	}

}


// Scrollspy - set visual colors, and set active visual
// Will change the color of each visual so it is readable on a light/dark background as the user scrolls down the page. Also will set the 'active' visual based on which section is under the pager.
prelude_determine_visual_status(); // Also run in the callback in web-font-loader.js
window.addEventListener( 'scroll', prelude_determine_visual_status, { passive: true } );

function prelude_determine_visual_status(){
	// console.log( 'running prelude_determine_visual_status()' );
	try {

		let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
		scrollTop = scrollTop + (window.innerHeight / 2); // The horizontal middle of the screen.
		// console.log('scrollTop = ' + scrollTop);

		let edges_have_crossed = false;

		for( let i=0; i<content_container_boundaries.length; i++){

			var fauxScrollTop = 0;
			// scrollTop is measured a little differently if you're scrolling up or down. When scrolling down, you want to see when the bottom edge of the image touches the boudnary of a section. When scrolling up, you want to use the top edge of the image.
			if( window.jp_scrollDirection === 'down' ){
				fauxScrollTop = scrollTop;

			} else if( window.jp_scrollDirection === 'up' ){
				fauxScrollTop = scrollTop - (content_container_boundaries[i].height * .25);
			}
			// console.log(fauxScrollTop);

			edges_have_crossed = fauxScrollTop > content_container_boundaries[i]['top'] && fauxScrollTop < content_container_boundaries[i]['bottom'];
			// console.log(edges_have_crossed);

			if( edges_have_crossed && !content_container_boundaries[i]['element'].classList.contains('active') ){
				for( let j=0; j<content_container_boundaries.length; j++){
					content_container_boundaries[j]['element'].classList.remove('active');
				}
				content_container_boundaries[i]['element'].classList.add('active');

				let row_num = content_container_boundaries[i]['element'].dataset.contentRowNum;
				let matching_visual = document.querySelector('[data-visual-row-num="'+row_num+'"]');
				// console.log('row_num = ' + row_num);
				if( !matching_visual.classList.contains('active') ){
					for( let j=0; j<visual_containers.length; j++){
						visual_containers[j].classList.remove('active');
						if( visual_containers[j].querySelector('video') ){
							visual_containers[j].querySelector('video').pause();
						}

					}
					matching_visual.classList.add('active');
				}


			}
		}

	}
	catch(error){
		console.log('Error in `prelude_determine_visual_status()`');
		console.log(error);
	}
}
