var $ = require('jquery');
var webfontloader = require('./libs/webfontloader-1.6.28.js');
var lazysizes = require('./libs/lazysizes-5.3.0.js');
var jpScrollDirection = require('./libs/jpScrollDirection.js');
var jpInView = require('./libs/jpInView.js');
var scrollMonitor = require('scrollmonitor');
var matchHeight = require('./libs/matchHeight');

// Polyfill for `position: sticky`
// https://github.com/wilddeer/stickyfill
// npm install stickyfilljs --save
import stickyFill from 'stickyfilljs';

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if( isIE11 ){
	let ie11_content_visuals = document.querySelectorAll('.content-with-visual__visual-row');
	stickyFill.add(ie11_content_visuals);

	document.documentElement.classList.add('is-ie11');
}

// npm install smoothscroll-polyfill --save
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

$(document).ready(function () {
    $('.card__content').matchHeight();
})

/*
webfontloader.load({
	// GOOGLE FONTS
	// google: {
	// 	families: [
	// 		'Barlow Condensed:300,400,500,600,700'
	// 	]
	// },
	// TYPEKIT
	// typekit: { id: 'abc123' },
	// CALLBACK WHEN FONTS LOAD
	// active: ali_fonts_active_cb
});
*/

// Callback function for when the fonts are loaded.
function ali_fonts_active_cb() {

}

// Use lazysizes to get lazy loading on css background images.
document.addEventListener('lazybeforeunveil', function (e) {
	// console.log('START lazybeforeunveil');
	// console.log(e);
	try {
		var bg = e.target.getAttribute('data-bg');
    if (bg) {
			e.target.style.backgroundImage = 'url(' + bg + ')';
    }

	} catch (error) {
		console.log('Error with lazyload background:');
		console.log(error);
	}

});

// This will lazyload the <video> elements by looping through for the `data-src` attributes and copying those to the `src` attributes. The <video>s must have the .lazyVideo class.
function prelude_lazyload_videos(){

	try {

		var lazyVideos = [].slice.call(document.querySelectorAll(".lazyVideo"));
		// console.log(lazyVideos);
		for( let i=0; i < lazyVideos.length; i++ ){
			// console.log( lazyVideos[i].querySelectorAll('source') );
			let sources = [].slice.call(lazyVideos[i].querySelectorAll('source'));

			let sources_touched = false;
			for( let j=0; j < sources.length; j++ ){
				if( sources[j].getAttribute('data-src') ){
					sources[j].setAttribute('src', sources[j].getAttribute('data-src') );
					sources_touched = true;
				}
			}
			if( sources_touched ){
				lazyVideos[i].load(); // This is for Firefox to update the video element in the DOM after the src attributes are set.
			}

		}

	} catch (error) {
		console.log('Error lazy loading the videos:');
		console.log(error);
	}

}
prelude_lazyload_videos();



// Detect if windows
try {
    if (navigator.platform.indexOf('Win') > -1) {
        document.documentElement.classList.add('is-windows');
    }
} catch (error) {
    console.log('Error detecting OS:');
    console.log(error);
}



// Templates
// var search_js = require('./search.js');

// Components
var office_locations = require('./components/office-locations');
var site_header = require('./components/site-header');
var site_footer = require('./components/site-footer');
var wysiwyg = require('./components/wysiwyg');

// Layouts
var hero_js = require('./layouts/hero');
// var content_with_visual_js = require('./layouts/content-with-visual');
var content_with_visual_js = require('./layouts/content-with-visual2');
var content_rotator_js = require('./layouts/content-rotator');
var tabbed_content_js = require('./layouts/tabbed-content');
